import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const InactivityHandler = ({ timeoutDuration = 10000, onInactivity }) => {
    useEffect(() => {
        let timeout;

        const resetTimer = () => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                if (onInactivity) onInactivity();
            }, timeoutDuration);
        };

        // Attach event listeners
        window.onload = resetTimer;
        window.onmousemove = resetTimer;
        window.onkeypress = resetTimer;
        window.onclick = resetTimer;
        window.onscroll = resetTimer;

        return () => {
            // Cleanup event listeners on component unmount
            window.onload = null;
            window.onmousemove = null;
            window.onkeypress = null;
            window.onclick = null;
            window.onscroll = null;
            clearTimeout(timeout);
        };
    }, [timeoutDuration, onInactivity]);

    return null; // This component only handles side effects
};

export default InactivityHandler;

InactivityHandler.propTypes = {
    timeoutDuration: PropTypes.number,
    onInactivity: PropTypes.func,
};