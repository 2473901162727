import React, { useEffect, useState } from 'react';
import StaticPageContainer from '../../components/static-page';
import { getDataFromCmsApi } from '../../api/contentful-service';
import { parseHtml } from '../../utils/utils';

function MoniesInTrust(props) {
    const [page, setPage] = useState();

    useEffect(() => {
        getPage();
    }, []);

    const getPage = async () => {
        let result = await getDataFromCmsApi("webpage/moniesInTrust");
        setPage(result);
    };

    return (
        page &&
        <StaticPageContainer title={page.pageTitle}>
            {parseHtml(page.content)}
        </StaticPageContainer>
    );
}

export default MoniesInTrust;