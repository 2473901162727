import React, { useEffect } from "react";
import { connect } from "react-redux";
import { handleAuthenticationCallback } from "../_actions/auth-action";
import { useNavigate } from 'react-router-dom';
import { govHubSubPath } from '../utils/utils.js';
import PropTypes from 'prop-types';

const mapStateToProps = (state) => {
  return {
    user: state.user
  };
};
let Callback = ({ dispatch, user }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleRedirect = async () => {
      // Dispatch to complete Auth0 callback
      await dispatch(handleAuthenticationCallback());

      // Retrieve post-login URL
      const postLoginRedirect = localStorage.getItem("postLoginRedirect");

      // Navigate to stored redirect or fallback
      navigate(postLoginRedirect || `/${govHubSubPath}/my-dashboard`, { replace: true });

      // Clean up localStorage
      localStorage.removeItem("postLoginRedirect");
    };

    handleRedirect();
  }, [dispatch, navigate]);

  return <div className="text-center">Loading user profile...</div>;
};

Callback = connect(mapStateToProps)(Callback);

Callback.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.object
};

export default Callback;
