import React from "react";
import { useParallax } from "react-scroll-parallax";
import Alerts from "./alerts";
import BannerActionCard from "../action-card/link-action-card-sub";
import { HistoryOutlined, Paid } from "@mui/icons-material";
import { govHubSubPath } from '../../utils/utils.js';
import { useNavigate } from "react-router";

const tileList = [
    {
        title: "View & Pay My Property Tax Bill",
        link: "/" + govHubSubPath + "/property-tax",
        icon: <Paid sx={{ fontSize: "60px", mb: 2 }} />,
    },
    {
        title: "View My Property Tax Payments History",
        link: "/" + govHubSubPath + "/my-dashboard#my-payments",
        icon: <HistoryOutlined sx={{ fontSize: "60px", mb: 2 }} />,
    },
];

const Banner = () => {
    const welcome = useParallax({ scale: [1.15, 0.95, "easeInQuad"] });
    const alert = useParallax({ scale: [1, 1.1, "easeInQuad"] });
    const navigate = useNavigate();

    return (
        <div className="banner">
            <div className="banner-wrapper">
                <div className="welcome-atc container">
                    <div ref={welcome.ref}>
                        <div className="welcome">Welcome</div>
                        <div className="atc">To the Auditor-Controller/ Treasurer/ Tax Collector</div>
                    </div>
                </div>
                <div className="alerts container pb-5" ref={alert.ref}>
                    <div className="d-flex flex-column align-items-center">
                        <div className="grid-container grid-style-2 gap-4" style={{ maxWidth: `${440 * tileList.length}px` }}>
                            {tileList.map((tile, i) => (
                                <div key={i}>
                                    <BannerActionCard
                                        title={tile.title}
                                        handleClick={() => {
                                            return navigate(tile.link);
                                        }}
                                        icon={tile.icon}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="pt-3 pb-5 py-md-5">
                        <Alerts />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Banner;
