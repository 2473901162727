
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSecondaryToken, signIn } from '../../services/auth';
import PropTypes from 'prop-types';
import LoginButton from '../login-button/login-button';
import { Button } from '@mui/material';
import Loader from '../loader.js';

GSGDirectChargesWidget.propTypes = {
    triggerRerender: PropTypes.bool
};


function GSGDirectChargesWidget({ triggerRerender }) {
    const [language, setLanguage] = useState("en");
    const [isDropdownRendered, setIsDropdownRendered] = useState(false);
    const [GATag, setGATag] = useState('');
    const [loading, setLoading] = useState(true); // Loader state
    const isAuthenticated = useSelector((state) => {
        if (state.user)
            return state.user
        return null;
        console.log("state", state);
    } //state.auth.isAuthenticated);
    );
    
    useEffect(() => {
        const checkDropdown = setInterval(() => {
            const languageDropdown = document.getElementsByClassName("gt_selector")[0];
            if (languageDropdown) {
                clearInterval(checkDropdown);
                setIsDropdownRendered(true);
                languageDropdown.addEventListener('change', handleLanguageDropdownChange);
                handleLanguageDropdownChange(); // Ensure initial load checks language
            }
        }, 100);

        return () => {
            clearInterval(checkDropdown);
            const languageDropdown = document.getElementsByClassName("gt_selector")[0];
            if (languageDropdown) {
                languageDropdown.removeEventListener('change', handleLanguageDropdownChange);
            }
        };
    }, []);

    const handleLanguageDropdownChange = () => {
        const selectedValue = document.getElementsByClassName("gt_selector")[0]?.value.substring(5);
        setLanguage(selectedValue === "es" ? "es" : "en");
    };

    const handleLogin = () => {
        signIn();
    }
    const exeJs = () => {
        if (isAuthenticated) {
            window.DirectCharge.attach({
                site: 'ca-sanbernardino/direct-charge',
                elementSelector: '#gsg-direct-charge',
                environment: 'beta',
                language: language,
                getJwt: () => getSecondaryToken().then(token => token),
                enableTestMode: false,
                initialPath: '/',
                onError: function (error) {
                    alert(error.message);
                },
            });
        }
    };
    useEffect(() => {
        if (isAuthenticated === undefined) {
            // Wait for authentication check to finish
            setLoading(true);
        } else {
            // Once auth check is complete, disable loading
            setLoading(false);
        }
    }, [isAuthenticated]);

    useEffect(() => {
        if (isDropdownRendered && isAuthenticated) {
            exeJs();
        }
    }, [isAuthenticated, triggerRerender, language, isDropdownRendered]);

    if (loading) {
        // Render a loader while authentication is being checked
        return <Loader />
    }

    return (
        <div className="gsg-container">
            <div className="container mt-5">
                <div className="row">
                    {isAuthenticated ? (
                        <div id="gsg-direct-charge"></div>
                    ) : (
                        <div>
                            <p className='lead'>Please log in to access Direct Charges.</p>
                            <Button onClick={handleLogin} variant="contained" color="primary">Log In</Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default GSGDirectChargesWidget;
