import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Container,
    Box,
    TextField,
    Button,
    Typography,
    Alert,
    Paper
} from '@mui/material';
import { validateCredentials } from '../../utils/envAuthUtils';

const EnvironmentLogin = ({ onLogin }) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (validateCredentials(username, password)) {
            sessionStorage.setItem('authenticated', 'true'); // Save login state
            onLogin();
        } else {
            setError('Invalid username or password');
        }
    };
    return (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{ padding: 4, mt: 8 }}>
                <img src='/assets/logos/sb-logo-blue.png' alt='logo' style={{ width: '100%', marginBottom: 16 }} />
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    {process.env.REACT_APP_ENV} Login
                </Typography>
                {error && (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {error}
                    </Alert>
                )}
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                    }}
                >
                    <TextField
                        label="Username"
                        variant="outlined"
                        fullWidth
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        size="large"
                    >
                        Log In
                    </Button>
                </Box>
            </Paper>
        </Container>
    )
}

EnvironmentLogin.propTypes = {
    onLogin: PropTypes.func.isRequired,
}

export default EnvironmentLogin