import About from '../pages/about';
import ExecTeam from '../pages/exec-team';
import Home from '../pages/home/home.js';
import TaxCollectorHome from '../pages/tax-collector/index';
//import PayOnline from '../pages/tax-collector/pay-online';
import NewsPage from '../pages/news-publications/news-page';
import PublicationsPage from '../pages/news-publications/publications-page';
import HoursAndLocationsPage from '../pages/hours-and-locations/hours-and-locations-page';
import TreasurerHome from '../pages/treasurer';
import InvestmentPool from '../pages/treasurer/investment-pool';
import RFI from '../pages/treasurer/rfi.js';
import { default as TreasurerContact } from '../pages/treasurer/contact-page';
import InvestmentDashboards from '../pages/treasurer/investment-dashboards.js'
import Error404 from '../pages/404';
import LegallyMandatedDuties from "../pages/treasurer/legally-mandated-duties.js"
import TreasurerReports from '../pages/treasurer/treasurer-reports';
import TreasurerAbout from '../pages/treasurer/treas-about.js';
import DivisionHomepageLayout from '../components/layouts/division-homepage-layout.js';
import AdministrationHome from '../pages/administration/index.js';
import DisbursementsHome from '../pages/disbursements/index.js';
import InformationTechnologyHome from '../pages/information-technology/index.js';
import AuditorHome from '../pages/auditor/index.js';
import PropertyTaxHome from '../pages/property-tax/index.js';
import RevenueRecoveryHome from '../pages/revenue-recovery/index.js';
import SAPHome from '../pages/sap-center-of-excellence/index.js';
import InformationTechnologyAbout from '../pages/information-technology/it-about.js';
import Callback from '../containers/callback.js';
import DocumentSearch from '../pages/document-search/document-search.js';
import ImportantDates from '../pages/tax-collector/important-dates';
//import Profile from '../pages/profile/index.js';
//import PropertyInformationHome from '../pages/property-information/index.js';
import JotFormContainer from '../components/jotform-container.js';
import TaxCollectorClosureSchedule from '../pages/tax-collector/tax-collector-closure-schedule';
import SupplementalTaxBillInfo from '../pages/tax-collector/supplemental-tax-bill-info';
import DefaultLayout from '../components/layouts/default-layout.js';
import AwardsAndAccolades from '../pages/awards-and-accolades/awards-and-accolades.js';
import DisbursementsAbout from '../pages/disbursements/disb-about.js';
import AuditorAbout from '../pages/auditor/auditor-about.js';
import DivisionServiceSearchResult from '../components/search/division-service-search-result.js';
import TaxCollectorAbout from '../pages/tax-collector/tc-about.js';
import ControllerHome from '../pages/controller/index.js';
import RevenueRecoveryAbout from '../pages/revenue-recovery/rvr-about.js';
import SAPAbout from '../pages/sap-center-of-excellence/sap-about.js';
import ControllerAbout from '../pages/controller/controller-about.js';
import PropertyTaxAbout from '../pages/property-tax/pt-about.js';
import FAQ from '../pages/faq/faq.js';
import NewHomeownerInformation from '../pages/tax-collector/new-homeowner-information.js';
import UnderstandingPostmarks from '../pages/tax-collector/understanding-postmarks.js';
import UnderstandingYourTaxBill from '../pages/tax-collector/understanding-your-tax-bill.js';
import PropertyTaxPostponement from '../pages/tax-collector/property-tax-postponement.js';
import GovHubServiceLinks from '../pages/govhub-services/govhub-service-links.js';
import GovHubServices from '../pages/govhub-services/govhub-services.js';
import { govHubSubPath } from '../utils/utils.js';
import TaxSale from '../pages/tax-collector/tax-sale.js';
import LegalPublications from '../pages/tax-collector/legal-publications.js';
import BulkTaxPayments from '../pages/tax-collector/bulk-tax-payments.js';
import AchWirePayment from '../pages/tax-collector/ach-wire-payment.js';
import SuccessorAgencyDissolutionInformation from '../pages/property-tax/successor-agency-dissolution-information.js';
import TermsAndConditions from '../pages/tax-collector/terms-and-conditions.js';
import ExcessProceeds from '../pages/tax-collector/excess_proceeds';
import ReportFraudWasteAbuse from '../pages/auditor/report-fraud-waste-abuse.js';
import IndependenceOfTheAuditorFunction from '../pages/auditor/independence_of_the_auditor_function.js';
import PrivacySettings from '../pages/privacy-settings.js';
import PrivacyPolicy from '../pages/privacy-policy.js';
import DirectCharges from '../pages/property-tax/direct-charges.js';
import GeneralAccounting from '../pages/controller/general-accounting.js';
import ManagementServices from '../pages/controller/management-services.js';
import ControllerAwards from '../pages/controller/controller-awards.js';
import CollectionServicesPage from '../pages/revenue-recovery/collection-services.js';
import VictimServices from '../pages/revenue-recovery/victim-services.js';
import MoniesInTrust from '../pages/revenue-recovery/monies-in-trust.js';
import LegalEnforcements from '../pages/revenue-recovery/legal-enforcements.js';

const divHomePage = {
    treasurer: { tag: 'treasurer', title: 'Treasurer Division', divisionAbbreviation: 'TRE' },
    taxCollector: { tag: 'taxCollector', title: 'Tax Collector Division', divisionAbbreviation: 'TXC' },
    itd: { tag: 'itd', title: 'Information Technology Division', divisionAbbreviation: 'ITD' },
    propertyTax: { tag: 'propertyTax', title: 'Property Tax Division', divisionAbbreviation: 'PT' },
    internalAudits: { tag: 'internalAudits', title: 'Internal Audits Division', divisionAbbreviation: 'AUD' },
    revenueRecovery: { tag: 'revenueRecovery', title: 'Revenue Recovery Division', divisionAbbreviation: 'RVR' },
    sap: { tag: 'sap', title: 'SAP Center of Excellence Division', divisionAbbreviation: 'SAP' },
    controller: { tag: 'controller', title: 'Controller Division', divisionAbbreviation: 'CTL' },
    administration: { tag: 'administration', title: 'Administration Division', divisionAbbreviation: 'ADM' },
    disbursements: { tag: 'disbursements', title: 'Disbursements Division', divisionAbbreviation: 'DSB' }
}


const routes = [
    {
        path: '*', component: <Error404 />, meta: { title: '404 - Page Not Found', description: 'The page you are looking for does not exist.' }
    },
    {
        path: '/Home', component: <Home />, meta: { title: 'Home', description: 'Welcome to the Home page.' }
    },
    {
        path: '/', component: <Home />, meta: { title: 'Home', description: 'Welcome to the Home page.' }
    },
    {
        path: '/callback', component: <Callback />, meta: { title: 'Callback', description: 'Callback page.' }
    },
    {
        path: '/tax-collector', component: <DivisionHomepageLayout division={divHomePage.taxCollector}><TaxCollectorHome division={divHomePage.taxCollector} /></DivisionHomepageLayout>, meta: { title: 'Tax Collector', description: 'Tax Collector Home page.' }
    },
    {
        path: '/tax-collector/about', component: <DivisionHomepageLayout division={divHomePage.taxCollector}><TaxCollectorAbout division={divHomePage.taxCollector} /></DivisionHomepageLayout>, meta: { title: 'About Tax Collector', description: 'About the Tax Collector.' }
    },
    {
        path: '/tax-collector/supplemental-tax-bill-info', component: <DivisionHomepageLayout division={divHomePage.taxCollector}><SupplementalTaxBillInfo /></DivisionHomepageLayout>, meta: { title: 'Supplemental Tax Bill Info', description: 'Information about Supplemental Tax Bills.' }
    },
    //{
    //    path: '/tax-collector/pay-online', component: <PayOnline></PayOnline>
    //},
    {
        path: '/tax-collector/tax-collector-closure-schedule', component: <DivisionHomepageLayout division={divHomePage.taxCollector}><TaxCollectorClosureSchedule></TaxCollectorClosureSchedule></DivisionHomepageLayout>, meta: { title: 'Tax Collector Closure Schedule', description: 'Schedule of Tax Collector closures.' }
    },
    {
        path: '/tax-collector/important-dates', component: <DivisionHomepageLayout division={divHomePage.taxCollector}><ImportantDates /></DivisionHomepageLayout>, meta: { title: 'Important Dates', description: 'Important dates for Tax Collector.' }
    },
    {
        path: '/tax-collector/new-homeowner-information', component: <DivisionHomepageLayout division={divHomePage.taxCollector}><NewHomeownerInformation /></DivisionHomepageLayout>, meta: { title: 'New Homeowner Information', description: 'Information for new homeowners.' }
    },
    {
        path: '/tax-collector/understanding-postmarks', component: <DivisionHomepageLayout division={divHomePage.taxCollector}><UnderstandingPostmarks /></DivisionHomepageLayout>, meta: { title: 'Understanding Postmarks', description: 'Understanding postmarks for Tax Collector.' }
    },
    {
        path: '/tax-collector/understanding-your-tax-bill', component: <DivisionHomepageLayout division={divHomePage.taxCollector}><UnderstandingYourTaxBill /></DivisionHomepageLayout>, meta: { title: 'Understanding Your Tax Bill', description: 'Understanding your tax bill.' }
    },
    {
        path: '/tax-collector/property-tax-postponement', component: <DivisionHomepageLayout division={divHomePage.taxCollector}><PropertyTaxPostponement /></DivisionHomepageLayout>, meta: { title: 'Property Tax Postponement', description: 'Information about property tax postponement.' }
    },
    {
        path: '/tax-collector/tax-sale', component: <DivisionHomepageLayout division={divHomePage.taxCollector}><TaxSale /></DivisionHomepageLayout>, meta: { title: 'Tax Sale', description: 'Information about tax sales.' }
    },
    {
        path: '/tax-collector/tax-sale/legal-publications', component: <DivisionHomepageLayout division={divHomePage.taxCollector}><LegalPublications /></DivisionHomepageLayout>, meta: { title: 'Legal Publications', description: 'Legal publications for tax sales.' }
    },
    {
        path: '/tax-collector/tax-sale/terms-and-conditions', component: <DivisionHomepageLayout division={divHomePage.taxCollector}><TermsAndConditions /></DivisionHomepageLayout>, meta: { title: 'Terms and Conditions', description: 'Terms and Conditions for tax sales.' }
    },
    {
        path: '/tax-collector/tax-sale/excess-proceeds', component: <DivisionHomepageLayout division={divHomePage.taxCollector}><ExcessProceeds /></DivisionHomepageLayout>, meta: { title: 'Excess Proceeds', description: 'Excess Proceeds for tax sales.' }
    },
    {
        path: '/tax-collector/bulk-tax-payments', component: <DivisionHomepageLayout division={divHomePage.taxCollector}><BulkTaxPayments /></DivisionHomepageLayout>, meta: { title: 'Bulk Tax Payments', description: 'Information about bulk tax payments.' }
    },
    {
        path: '/tax-collector/ach-wire-payment', component: <DivisionHomepageLayout division={divHomePage.taxCollector}><AchWirePayment /></DivisionHomepageLayout>, meta: { title: 'ACH/Wire Payment', description: 'Information about ACH/Wire payments.' }
    },
    {
        path: '/exec-team', component: <DefaultLayout title="ATC Executives" ><ExecTeam /></DefaultLayout>, meta: { title: 'Executive Team', description: 'Meet the ATC Executive Team.' }
    },
    {
        path: '/about', component: <DefaultLayout title="About ATC" ><About /></DefaultLayout>, meta: { title: 'About ATC', description: 'Learn more about ATC.' }
    },
    {
        path: '/treasurer', component: <DivisionHomepageLayout division={divHomePage.treasurer}><TreasurerHome division={divHomePage.treasurer} /></DivisionHomepageLayout>, meta: { title: 'Treasurer', description: 'Treasurer Home page.' }
    },
    {
        path: '/treasurer/investment-pool', component: <DivisionHomepageLayout division={divHomePage.treasurer}><InvestmentPool /></DivisionHomepageLayout>, meta: { title: 'Investment Pool', description: 'Information about the Investment Pool.' }
    },
    {
        path: '/treasurer/rfi', component: <DivisionHomepageLayout division={divHomePage.treasurer}><RFI /></DivisionHomepageLayout>, meta: { title: 'RFI', description: 'Request for Information.' }
    },
    {
        path: '/treasurer/rfi/:formIdentifier', component: <JotFormContainer pageTitle="Treasurer" />, meta: { title: 'RFI Form', description: 'Request for Information Form.' }
    },
    {
        path: '/treasurer/legally-mandated-duties', component: <DivisionHomepageLayout division={divHomePage.treasurer}><LegallyMandatedDuties /></DivisionHomepageLayout>, meta: { title: 'Legally Mandated Duties', description: 'Information about legally mandated duties.' }
    },
    {
        path: '/treasurer/reports', component: <DivisionHomepageLayout division={divHomePage.treasurer}><TreasurerReports /></DivisionHomepageLayout>, meta: { title: 'Treasurer Reports', description: 'Reports from the Treasurer.' }
    },
    {
        path: '/treasurer/contact', component: <DivisionHomepageLayout division={divHomePage.treasurer}><TreasurerContact /></DivisionHomepageLayout>, meta: { title: 'Contact Treasurer', description: 'Contact the Treasurer.' }
    },
    {
        path: '/treasurer/investment-dashboards', component: <DivisionHomepageLayout division={divHomePage.treasurer}><InvestmentDashboards /></DivisionHomepageLayout>, meta: { title: 'Investment Dashboards', description: 'Investment dashboards.' }
    },
    {
        path: '/news', component: <DefaultLayout title="News and Newsletters"><NewsPage></NewsPage></DefaultLayout>, meta: { title: 'News', description: 'Latest news and newsletters.' }
    },
    {
        path: '/newsletters', component: <DefaultLayout title="News and Newsletters"><PublicationsPage></PublicationsPage></DefaultLayout>, meta: { title: 'Newsletters', description: 'Latest newsletters.' }
    },
    {
        path: '/hours-and-locations', component: <DefaultLayout title="Hours, Locations, and Contact Numbers"><HoursAndLocationsPage /></DefaultLayout>, meta: { title: 'Hours, Locations, and Contact Numbers', description: 'Find our hours, locations, and contact numbers.' }
    },
    {
        path: '/:formIdentifier', component: <JotFormContainer pageTitle="Contact Us" />, meta: { title: 'Contact Us', description: 'Get in touch with us.' }
    },
    {
        path: '/awards-and-accolades', component: <DefaultLayout title="Awards and Accolades"><AwardsAndAccolades /></DefaultLayout>, meta: { title: 'Awards and Accolades', description: 'Our awards and accolades.' }
    },
    {
        path: '/treasurer/about', component: <DivisionHomepageLayout division={divHomePage.treasurer}><TreasurerAbout division={divHomePage.treasurer} /></DivisionHomepageLayout>, meta: { title: 'About Treasurer', description: 'Learn more about the Treasurer.' }
    },
    {
        path: '/administration', component: <DivisionHomepageLayout division={divHomePage.administration}><AdministrationHome division={divHomePage.administration} /></DivisionHomepageLayout>, meta: { title: 'Administration', description: 'Administration Home page.' }
    },
    {
        path: '/disbursements', component: <DivisionHomepageLayout division={divHomePage.disbursements}><DisbursementsHome division={divHomePage.disbursements} /></DivisionHomepageLayout>, meta: { title: 'Disbursements', description: 'Disbursements Home page.' }
    },
    {
        path: '/disbursements/about', component: <DivisionHomepageLayout division={divHomePage.disbursements}><DisbursementsAbout division={divHomePage.disbursements} /></DivisionHomepageLayout>, meta: { title: 'About Disbursements', description: 'Learn more about Disbursements.' }
    },
    {
        path: '/information-technology', component: <DivisionHomepageLayout division={divHomePage.itd}><InformationTechnologyHome division={divHomePage.itd} /></DivisionHomepageLayout>, meta: { title: 'Information Technology', description: 'Information Technology Home page.' }
    },
    {
        path: '/information-technology/about', component: <DivisionHomepageLayout division={divHomePage.itd}><InformationTechnologyAbout division={divHomePage.itd} /></DivisionHomepageLayout>, meta: { title: 'About Information Technology', description: 'Learn more about Information Technology.' }
    },
    {
        path: '/internal-audits', component: <DivisionHomepageLayout division={divHomePage.internalAudits}><AuditorHome division={divHomePage.internalAudits} /></DivisionHomepageLayout>, meta: { title: 'Auditor', description: 'Auditor Home page.' }
    },
    {
        path: '/internal-audits/about', component: <DivisionHomepageLayout division={divHomePage.internalAudits}><AuditorAbout division={divHomePage.internalAudits} /></DivisionHomepageLayout>, meta: { title: 'About Auditor', description: 'Learn more about the Auditor.' }
    },
    {
        path: '/internal-audits/report-fraud-waste-abuse', component: <DivisionHomepageLayout division={divHomePage.internalAudits}><ReportFraudWasteAbuse division={divHomePage.internalAudits} /></DivisionHomepageLayout>, meta: { title: 'Report Fraud, Waste, and Abuse', description: 'Learn more about reporting Fraud, Waste, and Abuse.' }
    },
    {
        path: '/internal-audits/independence_of_the_auditor_function', component: <DivisionHomepageLayout division={divHomePage.internalAudits}><IndependenceOfTheAuditorFunction division={divHomePage.internalAudits} /></DivisionHomepageLayout>, meta: { title: 'Independence of the Auditor Function', description: 'Learn more about independence of the auditor function.' }
    },
    {
        path: '/property-tax', component: <DivisionHomepageLayout division={divHomePage.propertyTax}><PropertyTaxHome division={divHomePage.propertyTax} /></DivisionHomepageLayout>, meta: { title: 'Property Tax', description: 'Property Tax Home page.' }
    },
    {
        path: '/property-tax/about', component: <DivisionHomepageLayout division={divHomePage.propertyTax}><PropertyTaxAbout division={divHomePage.propertyTax} /></DivisionHomepageLayout>, meta: { title: 'About Property Tax', description: 'Learn more about Property Tax.' }
    },
    {
        path: '/tax-services/direct-charges', component: <DirectCharges division={divHomePage.propertyTax} />, meta: { title: 'Direct Charges', description: 'Learn more about Direct Charges.' }
    },
    {
        path: '/property-tax/successor-agency-dissolution-information', component: <DivisionHomepageLayout division={divHomePage.propertyTax}><SuccessorAgencyDissolutionInformation /></DivisionHomepageLayout>, meta: { title: 'Successor Agency Dissolution Information', description: 'Successor Agency Dissolution Information.' }
    },
    {
        path: '/revenue-recovery', component: <DivisionHomepageLayout division={divHomePage.revenueRecovery}><RevenueRecoveryHome division={divHomePage.revenueRecovery} /></DivisionHomepageLayout>, meta: { title: 'Revenue Recovery', description: 'Revenue Recovery Home page.' }
    },
    {
        path: '/revenue-recovery/about', component: <DivisionHomepageLayout division={divHomePage.revenueRecovery}><RevenueRecoveryAbout division={divHomePage.revenueRecovery} /></DivisionHomepageLayout>, meta: { title: 'About Revenue Recovery', description: 'Learn more about Revenue Recovery.' }
    },
    {
        path: '/sap', component: <DivisionHomepageLayout division={divHomePage.sap}><SAPHome division={divHomePage.sap} /></DivisionHomepageLayout>, meta: { title: 'SAP Center of Excellence', description: 'SAP Center of Excellence Home page.' }
    },
    {
        path: '/sap/about', component: <DivisionHomepageLayout division={divHomePage.sap}><SAPAbout division={divHomePage.sap} /></DivisionHomepageLayout>, meta: { title: 'About SAP Center of Excellence', description: 'Learn more about SAP Center of Excellence.' }
    },
    {
        path: '/controller', component: <DivisionHomepageLayout division={divHomePage.controller}><ControllerHome division={divHomePage.controller} /></DivisionHomepageLayout>, meta: { title: 'Controller', description: 'Controller Home page.' }
    },
    {
        path: '/controller/about', component: <DivisionHomepageLayout division={divHomePage.controller}><ControllerAbout division={divHomePage.controller} /></DivisionHomepageLayout>, meta: { title: 'About Controller', description: 'Learn more about the Controller.' }
    },
    {
        path: '/controller/general-accounting', component: <DivisionHomepageLayout division={divHomePage.controller}><GeneralAccounting /></DivisionHomepageLayout>, meta: { title: 'General Accounting', description: 'Controller General Accounting.' }
    },
    {
        path: '/controller/management-services', component: <DivisionHomepageLayout division={divHomePage.controller}><ManagementServices /></DivisionHomepageLayout>, meta: { title: 'Management Services', description: 'Controller Management Services.' }
    },
    {
        path: '/controller/awards', component: <DivisionHomepageLayout division={divHomePage.controller}><ControllerAwards /></DivisionHomepageLayout>, meta: { title: 'Controller Awards', description: 'Controller Controller Awards.' }
    },
    //{
    //    path: '/profile', component: <Profile />
    //},
    //{
    //    path: '/property-information', component: <PropertyInformationHome />
    //},

    {
        path: '/search/documents', component: <DocumentSearch />, meta: { title: 'Document Search', description: 'Search for documents.' }
    },
    {
        path: '/online-services/:formIdentifier', component: <JotFormContainer pageTitle="Online Services" />, meta: { title: 'Online Services', description: 'Access online services.' }
    },
    {
        path: '/forms/:formIdentifier', component: <JotFormContainer pageTitle="Online Services" />, meta: { title: 'Forms', description: 'Access forms.' }
    },
    {
        path: '/faq', component: <DefaultLayout title="FAQ"><FAQ /></DefaultLayout>, meta: { title: 'FAQ', description: 'Frequently Asked Questions.' }
    },
    {
        path: '/division-service-search-result', component: <DefaultLayout title="Search Result" ><DivisionServiceSearchResult /></DefaultLayout>, meta: { title: 'Search Result', description: 'Search results for division services.' }
    },
    {
        path: '/' + govHubSubPath, component: <GovHubServiceLinks></GovHubServiceLinks>, meta: { title: 'GovHub Services', description: 'Links to GovHub services.' }
    },
    {
        path: '/' + govHubSubPath + '/:serviceUrl*', component: <GovHubServices></GovHubServices>, meta: { title: 'GovHub Service', description: 'GovHub service details.' }
    },
    {
        path: '/privacy-settings', component: <DefaultLayout title="Privacy Settings"><PrivacySettings /></DefaultLayout>, meta: { title: 'Privacy Settings', description: 'Manage your privacy settings.' }
    },
    {
        path: '/privacy-policy', component: <DefaultLayout title="Privacy Policy"><PrivacyPolicy /></DefaultLayout>, meta: { title: 'Privacy Policy', description: 'Privacy policy of the San Bernardino ATC Website.' }
    },
    {
        path: '/revenue-recovery/collection-services', component: <DivisionHomepageLayout division={divHomePage.revenueRecovery}><CollectionServicesPage></CollectionServicesPage></DivisionHomepageLayout>, meta: { title: 'Collection Services', description: 'Collection services for Revenue Recovery.' }
    },
    {
        path: '/revenue-recovery/victim-services', component: <DivisionHomepageLayout division={divHomePage.revenueRecovery}><VictimServices></VictimServices></DivisionHomepageLayout>, meta: { title: 'Victim Services', description: 'Victim services for Revenue Recovery.' }
    },
    {
        path: '/revenue-recovery/monies-in-trust', component: <DivisionHomepageLayout division={divHomePage.revenueRecovery}><MoniesInTrust></MoniesInTrust></DivisionHomepageLayout>, meta: { title: 'Monies in trust held by Treasurer', description: 'Monies in trust held by Treasurer.' }
    },
    {
        path: '/revenue-recovery/legal-enforcements', component: <DivisionHomepageLayout division={divHomePage.revenueRecovery}><LegalEnforcements></LegalEnforcements></DivisionHomepageLayout>, meta: { title: 'Legal Enforcements', description: 'Legal enforcements for Revenue Recovery' }
    },
];

export default routes;
