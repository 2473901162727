import 'devextreme/dist/css/dx.light.css';
import "./styles/styles.scss";
import { ThemeProvider, createTheme } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import Layout from "./components/layouts/main";
import AppRoutes from "./_routes/app-routes";
import ScrollToTop from "./components/scroll-to-top";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useMeta from './hooks/useMeta';
import CookieConsentContainer from "./components/cookie-consent/cookie-consent-container";
import { GAContextProvider } from "./_contexts/ga-context/ga-context";
import config from 'devextreme/core/config';
import EnvironmentLogin from './components/env-login/env-login';
import { useEffect, useState } from 'react';
import ScriptLoader from './components/script-loader';
import InactivityHandler from './components/inactivity-handler';

config({
    licenseKey: process.env.REACT_APP_DEVEXTREME_LICENSE_KEY
});




const theme = createTheme({
    palette: {
        primary: {
            main: "#003366",
        },
        secondary: {
            main: "#cd9700",
        },
    },
});

const zendesk = { script: `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_KEY}`, id: 'ze-snippet' };

function App() {
    const meta = useMeta();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [chatbotLoaded, setChatbotLoaded] = useState(false);
    const environment = process.env.REACT_APP_ENV;

    const loadScript = ({ src, id = null }) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.id = id;
        document.body.appendChild(script);
    };
    useEffect(() => {
        // Check if the user is already authenticated
        const auth = sessionStorage.getItem('authenticated');
        if (auth === 'true') {
            setIsAuthenticated(true);
        }
    }, []);

    const handleScriptLoad = () => {
        setChatbotLoaded(true);
    };

    const handleInactivity = () => {
        const iframe = document.querySelector('iframe[title="Close message"]');

        if (iframe) {
            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
            const elements = iframeDocument.querySelectorAll('[class*="sc-1uf0igr-0"]');
            elements.forEach((element) => element.click());
        } else {
            console.error('Iframe not found');
        }
    };

    const handleLogin = () => {
        setIsAuthenticated(true);
    };

    const handleLogout = () => {
        sessionStorage.removeItem('authenticated');
        setIsAuthenticated(false);
    };

    // Render login page only for local, development or QA environments
    if (['development', 'qa', 'local'].includes(environment)) {
        if (!isAuthenticated) {
            return <EnvironmentLogin onLogin={handleLogin} />;
        }
    }

    return (

        <>
            <ScriptLoader
                scriptSrc={zendesk.script}
                scriptId={zendesk.id}
                onLoad={handleScriptLoad}
            />
            {chatbotLoaded && <InactivityHandler timeoutDuration={10000} onInactivity={handleInactivity} />}
            <GAContextProvider>
                <CookieConsentContainer />
                <HelmetProvider>
                    <ThemeProvider theme={theme}>
                        <div className="App">
                            <Helmet>
                                <title>{meta.title}</title>
                                <meta name="description" content={meta.description} />
                            </Helmet>
                            <ParallaxProvider>
                                <Layout>
                                    <Routes>
                                        {AppRoutes.map((route) => (
                                            <Route
                                                path={route.path}
                                                element={<ScrollToTop>{route.component}</ScrollToTop>}
                                                key={route.path}
                                            />
                                        ))}
                                    </Routes>
                                </Layout>
                            </ParallaxProvider>
                        </div>
                    </ThemeProvider>
                </HelmetProvider>
            </GAContextProvider>
        </>
    );
}

export default App;
