import React, { useEffect } from 'react';
import { getDataFromCmsApi } from '../../../api/contentful-service';
import { Avatar, Box } from '@mui/material';
import 'animate.css';
import './meet-the-team.scss';
import { useNavigate } from 'react-router-dom'; // For redirection

const circleOrder = [
    "Chief Deputy, Disbursements",
    "Chief Deputy, Internal Audits",
    "Chief Deputy, Revenue Recovery",
    "Chief Deputy, Tax Collector",
    "Chief Deputy, Property Tax",
    "Chief Deputy, SAP Center of Excellence",
    "Chief Deputy, Information Technology",
    "Chief Deputy, Treasurer",
    "Assistant Auditor-Controller/Treasurer/Tax Collector",    
    "Auditor-Controller/Treasurer/Tax Collector",
    "Assistant Auditor-Controller/Treasurer/Tax Collector",
    "Chief Deputy, Controller"
];

function MeetTheTeamLink() {
    const [team, setTeam] = React.useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch team data from an API or CMS
        const fetchTeamData = async () => {
            let response = await getDataFromCmsApi("TeamMember"); 
            const sortedItems = circleOrder.map((position, index) => {
                const matchIndex = response.findIndex(item => item.position === position);
                if (matchIndex !== -1) {
                  return response.splice(matchIndex, 1)[0];
                }
                return null;
            });
            setTeam(sortedItems);
        }
        fetchTeamData();
    }, []);

    const handleClick = () => {
        // Redirect to the "Meet the Team" page
        navigate('exec-team');
    };

    return (
        <Box className="team-container" onClick={handleClick}>
            <div className="meet-the-team team-flip-card" key="meetteam" style={{ zIndex: 100 }}>
                <div className="team-flip-card-inner">
                    {/* Front of the card */}
                    <div className="team-flip-card-front">
                        <Avatar
                            alt="Meet the team link"
                            src="./assets/images/team/meet-the-team.png"
                            className="meet-the-team-avatar"
                        />
                    </div>
                    {/* Back of the card */}
                    <div className="team-flip-card-back">
                        <Avatar
                            alt="Meet the team"
                            src="./assets/images/team/view-team-info.png" // You can replace with another image for the flip
                            className="meet-the-team-avatar"
                        />
                    </div>
                </div>
            </div>

            {team && team.map((member, index) => (
                <div className="team-member" key={index} style={{ zIndex: 10 - index }}>
                    <Avatar
                        alt={member.position}
                        src={member.imageUrl}
                        className='team-member-avatar'
                    />
                </div>
            ))}
        </Box>
    );
}

export default MeetTheTeamLink;
