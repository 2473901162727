//https://www.algolia.com/doc/ui-libraries/autocomplete/integrations/using-react/

import React, { useRef } from "react";
import { useNavigate } from 'react-router';
import '@algolia/autocomplete-theme-classic';
import { getAlgoliaResults } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch';
import { Autocomplete } from './autocomplete.js'
import DivisionServiceItem from './division-service-item.js'

const appid = process.env.REACT_APP_ALGOLIA_APP_ID;
const searchApiKey = process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY;
const indexName = process.env.REACT_APP_ALGOLIA_SERVICES_INDEX
const searchClient = algoliasearch(appid, searchApiKey);

function DivisionServiceSearchAutocomplete() {
   
    const navigate = useNavigate();
    const queryRef = useRef("");
    const handleClick = () => {
        if (queryRef.current) {
            navigate("/division-service-search-result?query=" + queryRef.current);
        }
    };


    return (
            <div className="col-12 col-sm-9 col-md-6 col-xl-4 px-4">
                <Autocomplete
                    openOnFocus={true}
                    onStateChange={({ state }) => {
                        queryRef.current = state.query;
                    }}
                    onSubmit={handleClick}
                    getSources={({ query }) => [
                        {
                            sourceId: 'DivisionService',
                            getItems() {

                                return getAlgoliaResults({
                                    searchClient,
                                    queries: [
                                        {
                                            indexName: indexName,
                                            query,
                                            params: {
                                                hitsPerPage: 5,
                                            },
                                        },
                                    ],
                                });
                            },
                            templates: {
                                item({ item, components }) {
                                    return <DivisionServiceItem hit={item} components={components} />;
                                },
                            },
                        },
                    ]}
                />
            </div>
    );
}
export default DivisionServiceSearchAutocomplete;
