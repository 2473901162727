import React from 'react'
import PropTypes from 'prop-types';
import { useParallax } from 'react-scroll-parallax';

const ContentSection = ({ header, children, backgroundClass }) => {
    const headerParallax = useParallax({ scale: [1.15, 1.25, 'easeInQuad'] })

    return (
        <div className={`content-section w-100 ${backgroundClass}`}>
            <div className='content py-5 px-3 px-lg-5 h-100'>
                {header != null && <div className='d-flex headerTitle pt-5 pb-4'>
                    <p className='display-6 text-center title' ref={headerParallax.ref}>{header}</p>
                </div>
                }
                <div className='py-3 h-100'>
                    {children}
                </div>
            </div>
        </div>
    )
}

ContentSection.propTypes = {
    header: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    backgroundClass: PropTypes.string
}

export default ContentSection