import React from 'react'
import DefaultLayout from '../../components/layouts/default-layout';
import GSGDirectChargesWidget from '../../components/gsg-widget/gsg-direct-charges-widget';

function DirectCharges() {
    return (<DefaultLayout title="Direct Charges" >
        <div>
            <GSGDirectChargesWidget />
        </div>
    </DefaultLayout>);
}

export default DirectCharges;
