import React from 'react'
import PropTypes from 'prop-types';

const MainSection = ({ header, children, backgroundClass }) => {
    return (
        <div className={`main-section w-100 ${backgroundClass}`}>
            <div className='content p-3 p-lg-5  py-lg-5 h-100'>
                {header != null && <div className='d-flex headerTitle'>
                    {/*<svg className='d-none d-md-block' width="20" height="100">*/}
                    {/*    <line x1="02" y1="0" x2="02" y2="80"></line>*/}
                    {/*</svg>*/}
                    <h1 className='display-4'><strong>{header}</strong></h1>
                </div>
                }
                <div className='py-5 h-100'>
                    {children}
                </div>
            </div>
        </div>
    )
}

MainSection.propTypes = {
    header: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    backgroundClass: PropTypes.string
}

export default MainSection