

export function addGAScript() {
    const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
    if (trackingId) {
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
        script.async = true;
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                window.dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', trackingId, {
                anonymize_ip: true,
                allow_ad_personalization_signals: false,
            });
        };
    } else {
        console.warn("Google Analytics tracking ID is not set.");
    }
}
