import React, { useEffect, useState } from 'react'
import "../icons";
import { ParallaxProvider } from "react-scroll-parallax";
import { Fade } from "react-awesome-reveal";
import ByTheNumberItem from './by-the-number-item';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getDataFromCmsApi } from "../../api/contentful-service";

const fadeDuration = 1500;

const ByTheNumbers = () => {

    const [data, setData] = useState(null);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const result = await getDataFromCmsApi("Accomplishment");
        setData(result);
    };

    return (
        <ParallaxProvider>
            <div className="parallax paralsec by-the-numbers d-flex justify-content-center align-items-center">
                <div className="bg-overlay py-5 my-5">
                    <Fade triggerOnce direction="left" duration={fadeDuration}>
                        <div className="row fluid d-flex justify-content-center">
                            <p className='display-6 text-center title header p-0 mb-4 mb-md-5'>Accomplishments</p>
                        </div>
                    </Fade>

                    <Fade triggerOnce direction="up" duration={fadeDuration} className="d-flex justify-content-center">
                        {data &&
                            <div className="row fluid d-flex justify-content-center container pb-5">
                                {data.map((item, index) => (
                                    <div key={index} className="my-3 col-12 col-md-6 col-lg-4 col-xl-3 mt-5">
                                        <div className="container text-center">
                                            <ByTheNumberItem 
                                                isCurrency={item.isCurrency} 
                                                number={item.quantity} 
                                                unit={item.unit} 
                                                description={item.description} 
                                                icon={<FontAwesomeIcon size='2x'/>}
                                            >
                                            </ByTheNumberItem>
                                        </div>
                                    </div>

                                ))}
                            </div>
                        }
                    </Fade>
                  
                </div>
            </div>
        </ParallaxProvider >
    )
}

export default ByTheNumbers