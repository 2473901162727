export const getCredentials = () => {
    const credentialsString = process.env.REACT_APP_CREDENTIALS;
    if (!credentialsString) {
        throw new Error('Environment variable REACT_APP_CREDENTIALS is missing!');
    }

    const pairs = credentialsString.split(';');
    return pairs.reduce((acc, pair) => {
        const [username, password] = pair.split(':');
        if (username && password) {
            acc[username.toLowerCase()] = password; // Map username to password
        }
        return acc;
    }, {});
};

export const validateCredentials = (username, password) => {
    const credentials = getCredentials();
    return credentials[username.toLowerCase()] === password;
};