import React, { useEffect, useState } from "react";
import DivisionContact from "../../components/division-homepage/division-contact";
import DivisionAboutBanner from "../../components/division-homepage/division-about-banner";
import DivisionServices from "../../components/division-homepage/division-services";
import AlertService from "../../components/alert-service/alert-service";
import PropTypes from "prop-types";
import DivisionNavTiles from "../../components/division-homepage/division-nav-tiles";
import { getDataFromCmsApi } from "../../api/contentful-service";
import { getColorByIndex, isExternalLink } from "../../utils/utils";
import { Typography } from "@mui/material";
import LinkActionCard from "../../components/action-card/link-action-card";

function RVRHome({ division }) {
  const [services, setServices] = useState();

  useEffect(() => {
    getServices();
  }, []);

  const getServices = async () => {
    let result = await getDataFromCmsApi(`DivisionService/${division.divisionAbbreviation}`);

    result = result.sort((a, b) => a.sortOrder - b.sortOrder).filter((service) => !service.isHomePage);
    setServices(result);
  };


  return (
    <>
      <div className="container">
        <AlertService divisionCode={division.divisionAbbreviation} />
        <Typography variant="h2" className="text-center">
          Our Services
        </Typography>
        <div className="grid-container grid-style-4 py-5">
          {services &&
            services.map((item, index) => (
              <LinkActionCard
                key={index}
                title={item.serviceLabel}
                linkDestination={item.link}
                cardColor={getColorByIndex(index)}
                target={isExternalLink(item.link) ? "_blank" : ""}
              />
            ))}
        </div>
      </div>
      <div>
        <DivisionAboutBanner
          divisionAbbreviation={division.divisionAbbreviation}
          divisionTag={division.tag}
          linkTo="/revenue-recovery/about"
        />
        <DivisionContact
          divisionAbbreviation={division.divisionAbbreviation}
          sectionTitle={"Contact Revenue Recovery"}
        />
      </div>
    </>
  );
}

RVRHome.propTypes = {
  division: PropTypes.shape({
    divisionAbbreviation: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
  }).isRequired,
};

export default RVRHome;
